import React, { useEffect } from "react"

import { Layout } from "@components/layout"
import {
  Hero,
  Attention,
  Values,
  Mission,
  Shopping,
  SpecialButton,
  Conditions,
  Reviews,
  Contact,
} from "@components/standard"

const IndexPage = ({ pageContext, location }) => {
  const { page } = pageContext
  const translationUrls = {
    pl:`/pl`,
    en:`/en`,
  }
  let language = null
  if(typeof window !== "undefined"){
    language = localStorage.getItem('language').replace("/","")
  }
  return (
    <Layout {...page[0]} translationUrl={translationUrls}>
      <Hero language={language}/>
      <Attention language={language}/>
      <Values />
      <Mission />
      <Shopping />
      <SpecialButton />
      <Conditions />
      <Reviews />
      <Contact />
    </Layout>
  )
}

export default IndexPage
